import React from "react";
import Img from "gatsby-image";
import "./index.css";
import "../style/font-style.css";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import AOS from "aos";
import "aos/dist/aos.css";
import { useStaticQuery, graphql, Link } from "gatsby";
import Head from "../components/head";
import Header from "../components/Header_normal";
import RecentNews from "../components/RecentNews";
import Profile from "../components/Profile";
import Media from "../components/Media";
import Content from "../components/content";
import Footer from "../components/footer";
import Pastevent from "../components/pastevent";
import { Hidden } from "@material-ui/core";
import BoxTypo from "../components/BoxTypo";
import NewsArea from "../components/newsArea";
import { StoreInfo } from "../components/StoreInfo";
import MediaQuery from "react-responsive";
import Pics from "../components/pics";

export default function Home() {
  if (typeof document !== `undefined`) {
    AOS.init({
      initClassName: "aos-init", // class applied after initialization
      animatedClassName: "aos-animate", // class applied on animation
      disableMutationObserver: false, // disables automatic mutations' detections (advanced)
      delay: 600, // values from 0 to 3000, with step 50ms
      duration: 1000, // values from 0 to 3000, with step 50ms
      easing: "ease", // default easing for AOS animations
      once: false,
      anchorPlacement: "bottom-bottom",
    });
  }
  const data = useStaticQuery(graphql`
    query {
      topImageSp: file(relativePath: { eq: "piccante_top_sp.png" }) {
        childImageSharp {
          fluid(maxWidth: 3000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      dhiaborittyo: file(relativePath: { eq: "dhiaborittyo.png" }) {
        childImageSharp {
          fluid(maxWidth: 2500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      piccoro: file(relativePath: { eq: "piccoro.png" }) {
        childImageSharp {
          fluid(maxWidth: 2500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      topImagePc: file(relativePath: { eq: "piccante_fv_pc.png" }) {
        childImageSharp {
          fluid(maxWidth: 2500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      dinerImage: file(relativePath: { eq: "diner-picture.png" }) {
        childImageSharp {
          fluid(maxWidth: 2500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      howtousePc: file(relativePath: { eq: "piccante_howtouse_pc.png" }) {
        childImageSharp {
          fluid(maxWidth: 2500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      howtouseSp: file(relativePath: { eq: "piccante_howtouse_sp.png" }) {
        childImageSharp {
          fluid(maxWidth: 2500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      vactorLine: file(relativePath: { eq: "vactorLine.png" }) {
        childImageSharp {
          fluid(maxWidth: 2500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      HitoyoshiImg: file(relativePath: { eq: "hitoyoshi-img.png" }) {
        childImageSharp {
          fluid(maxWidth: 2500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      FB: file(relativePath: { eq: "FB_logo_mono.png" }) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      IG: file(relativePath: { eq: "insta_logo_mono.png" }) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      ReverceDinerPicture: file(
        relativePath: { eq: "reverce-diner-picture.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 2500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      allMicrocmsNews {
        edges {
          node {
            newsId
            title
            date
            content
            image {
              url
            }
          }
        }
      }
    }
  `);

  const companyLists = ["Address", "Tel", "Mail"];
  const companyListsSec = [
    "東京都港区白金6-23-1",
    "03-6875-0618",
    "info@piccante.co.jp",
  ];

  return (
    <>
      <Head />
      <Box width="100%" bgcolor="#fff">
        <Header textColor="#343441" BGColor="#F7F6F4" />
        <Box
          className="fv"
          width="100%"
          margin="auto"
          pt={[0, 0, 5, 5]}
          pb="32px"
          position="relative"
          bgcolor="#fff"
        >
          <Hidden mdUp>
            <Img fluid={data.topImageSp.childImageSharp.fluid} />
          </Hidden>
          <Hidden smDown>
            <Img fluid={data.topImagePc.childImageSharp.fluid} />
          </Hidden>
          <Box margin="auto" width="90%">
            <BoxTypo fs={["24px", "36px", "48px"]} ls="0" mt={[5, 10]} fw="700">
              “Message .
            </BoxTypo>
            <BoxTypo margin="auto" width="95%" fs="14px">
              メッセージ
            </BoxTypo>
          </Box>

          <BoxTypo
            fs={["16px", "24px", "36px"]}
            ls={["1px", "3px"]}
            lh="200%"
            fw="700"
            width="80%"
            m={"auto"}
            mt={[3, 3, 5]}
          >
            人吉市を唐辛子で真っ赤に染めたい。
            <br />
            国内・世界へ日本産の唐辛子を届けます。{" "}
          </BoxTypo>
          <BoxTypo
            fs={["14px", "16px", "18px"]}
            ls={["1px", "3px"]}
            lh={["180%", "200%"]}
            textAlign="justify"
            width="85%"
            mt={3}
            pb={5}
            m={"auto"}
          >
            熊本県人吉市でのみ生産を行なっている、国産イタリアン唐辛子「人吉ぴっかんて」。国内で唯一、イタリアン唐辛子を生産しています。これまで輸入品しか流通していなかった通称カラブリアと呼ばれるイタリア品種の唐辛子を、どうにか国産で作れないものか、という思いから生産を始め、数年の試行錯誤ののちに完成しました。現在生産を行なっているのは「でぃあぼりっちょ」と「ペペロンチーノぴっころ」の２種です。{" "}
          </BoxTypo>

          {/* <Box textAlign="center">
            <BoxTypo fs={["24px", "36px", "48px"]} mt={[5, 10]} fw="700">
              “Products .
            </BoxTypo>
            <BoxTypo fs="14px">プロダクト</BoxTypo>
          </Box> */}
          <Box
            mt={5}
            maxWidth="1200px"
            width={["80%", "90%", "90%"]}
            m={"auto"}
          >
            <Grid container>
              <Grid item xs={12} sm={6}>
                <BoxTypo
                  fs={["18px", "18px", "24px"]}
                  ls={["1px", "3px"]}
                  lh={["180%", "200%"]}
                  textAlign="justify"
                  width="100%"
                  // maxWidth="1100px"
                  mt={3}
                  fw="700"
                  m={"auto"}
                >
                  でぃあぼりっちょ
                </BoxTypo>
                <Box margin="auto" textAlign="center" pb={2}>
                  <Img fluid={data.vactorLine.childImageSharp.fluid} />
                </Box>
                <BoxTypo
                  fs={["12px", "14px", "16px"]}
                  ls={["1px", "3px"]}
                  lh={["180%", "200%"]}
                  textAlign="justify"
                  width={["100%", "90%", "90%"]}
                  // maxWidth="1100px"
                  mt={1}
                  // m={"auto"}
                >
                  小粒で鮮やかな赤色、丸みを帯び可愛らしい見た目ですが,
                  実はハバネロと同じスコビル値の唐辛子。
                  そして辛さだけではなく、旨味も群を抜いてます。
                </BoxTypo>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Box pl={[0, 1, 1]} my={5}>
                  <Img fluid={data.dhiaborittyo.childImageSharp.fluid} />
                </Box>
              </Grid>
            </Grid>
          </Box>
          <Box
            mt={5}
            maxWidth="1200px"
            width={["80%", "90%", "90%"]}
            m={"auto"}
          >
            <Grid container>
              <Grid item xs={12} sm={6}>
                <BoxTypo
                  fs={["18px", "18px", "24px"]}
                  ls={["1px", "3px"]}
                  lh={["180%", "200%"]}
                  textAlign="justify"
                  width="100%"
                  // maxWidth="1100px"
                  mt={3}
                  fw="700"
                  m={"auto"}
                >
                  ペペロンチーノぴっころ
                </BoxTypo>
                <Box margin="auto" textAlign="center" pb={2}>
                  <Img fluid={data.vactorLine.childImageSharp.fluid} />
                </Box>
                <BoxTypo
                  fs={["12px", "14px", "16px"]}
                  ls={["1px", "3px"]}
                  lh={["180%", "200%"]}
                  textAlign="justify"
                  width={["100%", "90%", "90%"]}
                  // maxWidth="1100px"
                  mt={1}
                  // m={"auto"}
                >
                  小粒でオレンジがかった赤色、でぃあぼりっちょよりも辛さは控え目ですが、まるで”お出汁”を​吸わせたような旨味。ペペロンチーノが抜群に美味しくなります。
                </BoxTypo>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Box pl={[0, 1, 1]} my={5}>
                  <Img fluid={data.piccoro.childImageSharp.fluid} />
                </Box>
              </Grid>
            </Grid>
          </Box>
          <Box width="100%" maxWidth="800px" m={"auto"} mt={10}>
            <Hidden mdUp>
              <Img fluid={data.howtouseSp.childImageSharp.fluid} />
            </Hidden>
            <Hidden smDown>
              <Img fluid={data.howtousePc.childImageSharp.fluid} />
            </Hidden>
          </Box>
          <Box width="100%" bgcolor="#b8a07c" marginTop="64px">
            <BoxTypo
              color="#fff"
              fs={["24px", "36px", "48px"]}
              width={["90%", "70%", "60%"]}
              m={"auto"}
              fw="bold"
              ls="0"
            >
              “Store info.
            </BoxTypo>
            <BoxTypo
              color="#fff"
              fs={["11px", "12px", "14px"]}
              width={["90%", "70%", "55%"]}
              m={"auto"}
              fw="900"
              ls="10px"
            >
              店舗情報
            </BoxTypo>
          </Box>
          <Box
            display={["block", "block", "block", "flex"]}
            width={["100%", "90%", "70%"]}
            m={"auto"}
            py={["40px", "50px", "60px"]}
          >
            <Box
              display="flex"
              justifyContent="center"
              width={["100%", "100%", "100%", "70%"]}
              py={5}
            >
              <Box
                display="flex"
                flexDirection="column"
                justifyContent="space-around"
              >
                {companyLists.map((list, index) => (
                  <BoxTypo key={index} fs={["12px", "16px", "18px"]} lh="2.5">
                    {list}
                  </BoxTypo>
                ))}
              </Box>
              <Box
                display="flex"
                flexDirection="column"
                justifyContent="space-around"
                pl={["20px", "30px", "40px"]}
              >
                {companyListsSec.map((list, index) => (
                  <BoxTypo key={index} fs={["12px", "16px", "18px"]} lh="2.5">
                    {list}
                  </BoxTypo>
                ))}
              </Box>
            </Box>
            <Hidden lgUp>
              <BoxTypo
                width={["90%", "50%", "50%"]}
                m={"auto"}
                fs={["8px", "9px", "11px"]}
                pb={5}
              >
                ※ 系列店TRIPPERIA IL MACAONEの電話番号になります。
                <br />
                人吉ピッカンテの注文をしたいとお伝えください。
              </BoxTypo>
            </Hidden>
            <MediaQuery query="(max-width: 768px)">
              <Box>
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3242.324022408544!2d139.71927811514388!3d35.64438768020293!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x60188b11e38775e5%3A0x3c7e4da6cfb3d98b!2z44CSMTA4LTAwNzIg5p2x5Lqs6YO95riv5Yy655m96YeR77yW5LiB55uu77yS77yT4oiS77yR!5e0!3m2!1sja!2sjp!4v1626344166722!5m2!1sja!2sjp"
                  width="100%"
                  height="300"
                  style={{ border: "0" }}
                  allowfullscreen=""
                  loading="lazy"
                ></iframe>
              </Box>
            </MediaQuery>

            <MediaQuery query="(min-width: 769px)">
              <Box display="flex" justifyContent="center">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3242.324022408544!2d139.71927811514388!3d35.64438768020293!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x60188b11e38775e5%3A0x3c7e4da6cfb3d98b!2z44CSMTA4LTAwNzIg5p2x5Lqs6YO95riv5Yy655m96YeR77yW5LiB55uu77yS77yT4oiS77yR!5e0!3m2!1sja!2sjp!4v1626344166722!5m2!1sja!2sjp"
                  width="500"
                  height="300"
                  style={{ border: "0" }}
                  allowfullscreen=""
                  loading="lazy"
                ></iframe>
              </Box>
            </MediaQuery>
          </Box>
          <Hidden mdDown>
            <BoxTypo
              width={["95%", "90%", "60%"]}
              m={"auto"}
              fs={["8px", "9px", "11px"]}
              pb={5}
            >
              ※ 系列店TRIPPERIA IL MACAONEの電話番号になります。
              <br />
              人吉ピッカンテの注文をしたいとお伝えください。
            </BoxTypo>
          </Hidden>
          {/* <Box
            width="500px"
            maxWidth="300px"
            m={"auto"}
            display="flex"
            justifyContent="space-around"
          >
            <Link
              to="https://www.instagram.com/il_macaone/"
              target="_blank"
              rel="noopener noreferrer"
              width="100px"
            >
              <Img
                fluid={data.IG.childImageSharp.fluid}
                style={{ width: "60px", height: "auto" }}
              />
            </Link>
            <Link
              to="https://ja-jp.facebook.com/macaone.platino"
              target="_blank"
              rel="noopener noreferrer"
              width="300px"
            >
              <Img
                fluid={data.FB.childImageSharp.fluid}
                style={{ width: "60px", height: "auto" }}
              />
            </Link>
          </Box> */}
        </Box>
        {/* 2ページ目 */}

        <Footer textColor="#FFFFFF" BGColor="#343441" />
      </Box>
    </>
  );
}
